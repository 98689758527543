import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Img from 'gatsby-image'


class Staff extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      searchResult: [],
      loading: false,
      cognome: "",
      ruolo: ""
    }
  }

  search = (e) => {
    e.preventDefault();
  }

  handleChange(event) {
    this.setState({ruolo: event.target.value});
  }
 
  render() { 

    const { loading, cognome, ruolo } = this.state;

    const alldata = this.props.data.allContentfulPersona.edges.map(item => item.node);
    const placeholder = this.props.data.placeholder;
   
    const result = alldata.filter((item) => {
      return (!cognome || item.cognome.toLowerCase().startsWith(cognome.toLowerCase())) && (!ruolo || item.ruolo === ruolo)
    })

    const breadcrumb = [
      {"title":"Staff"}
    ];
    
    
    return (
      <Layout location={this.props.location}>
        <div className="container">
          <Helmet title="Staff"/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-12">
              <h1>Staff</h1>
            </div>
          </div>
        </div>
        <div id="ricerca-sfaff" className="section section--dark section--m-bottom" role="region" aria-labelledby="region3">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <form action="#" onSubmit={this.search} method="post" name="ricerca-staff" className="form">
                  <fieldset className="form__fieldset">
                    <legend className="form__legend visuallyhidden">Ricerca staff</legend>
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <div className="form__group">
                          <label htmlFor="cognome" className="form__label">Cognome</label>
                          <input value={this.state.cognome} onChange={(e) => this.setState({cognome: e.target.value})} type="text"  name="cognome" id="cognome" className="form__input" />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="form__group">
                          <label htmlFor="role" className="form__label">Ruolo</label>
                          <div className="form__group--select">
                            <select  value={this.state.ruolo}  onChange={(e) => this.handleChange(e)} name="ruolo" id="role">
                              <option value="">Seleziona ruolo</option>
                              <option value="Direttore">Direttore generale</option>
                              <option value="Direttore Sanitario">Direttore sanitario</option>
                              <option value="Docente">Docente</option>
                              <option value="Medico Veterinario a contratto">Dottore a contratto</option>
                              <option value="Ricercatore">Ricercatore</option>
                              <option value="Area Operatori">Area Operatori</option>
                              <option value="Area Collaboratori">Area Collaboratori</option>
                              <option value="Area Funzionari">Area Funzionari</option>
                              <option value="Responsabile Amministrativo">Responsabile Amministrativo</option>
                              <option value="Internship">Internship</option>
                              <option value="Assegnista">Assegnista</option>
                              <option value="Borsista">Borsista</option>
                              <option value="Specializzando dottorando">Specializzando dottorando</option>
                              <option value="Redattore Web e Amministrativo">Redattore Web e Amministrativo</option>
                              <option value="Redattore Web e Referente IT">Redattore Web e Referente IT</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                
                   
                   {/*  <div className="row">
                      <div className="col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center">
                        <input type="submit" value="Cerca" className="btn-link btn-link--block btn-link--red" />
                      </div>
                    </div> */}

                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container" ref="resultsContainer">
          {!loading &&
          <div className="row" id="elenco">
            <div className="col-12">
              <p>
                <strong>Sono stati trovati {result.length} risultati</strong>
              </p>
            </div>
          </div>
          }
          <div className="row">
            {loading &&
              <div className="col-sm-12 text-center loader">
                <img alt="loading" src="/images/loader.gif"/>
              </div>
            } 
            {result.map((sresult,index) => {
        

              return (
                <div key={index} className="col-6 col-md-4 col-xl-3">
                  <div className="staff-list">
                    {sresult.immagineLista ? (
                      <Img fluid={sresult.immagineLista.fluid} className="staff-list__img"/>
                    ) : (
                      <Img fluid={placeholder.childImageSharp.fluid} className="staff-list__img"/>
                    )} 
                    <div className="staff-list__detail">
                      <Link to={'/staff/'+sresult.friendlyUrl} title={"Vai alla scheda di " + sresult.cognome} className="staff-list__name">{sresult.nome} {sresult.cognome}</Link>
                      <div className="staff-list__role">{sresult.ruoloVisualizzato ? sresult.ruoloVisualizzato : sresult.ruolo}</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
       
        </div>
      </Layout>  
    )
  }

}

export default Staff;

export const query = graphql`
query StaffiQuery{
  allContentfulPersona(filter: {node_locale: {eq: "it-IT"} }, sort: {fields: [cognome]}) {
    edges {
      node {
        id
        nome
        cognome
        friendlyUrl
        ruolo
        ruoloVisualizzato
        immagineLista {
          fluid(maxWidth: 380){
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
  placeholder: file(relativePath: {eq: "staff-placeholder.png"}) {
    childImageSharp{
      fluid(maxWidth: 380){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`